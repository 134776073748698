export const SUBSCRIPTION_CHAT_EVENTS_FRAGMENT = `
  fragment subscriptionChatEventsFragment on EventOutput {
    type
    success
    requestId
    data {
      __typename
      ... on EventErrorOutput {
        errorMessage: message
        errorType: type
        payload
      }
      ... on EventGroupOutput {
        uid
        groupType: type
        active
        tempId
        createdTime
        schemaCode
        creatorUser {
          uid
          firstName
          lastName
          pictureFileResource {
              uid
              schemaCode
              path
          }
        }
        messages {
          uid
          type
          senttime
          senttimestamp
          content
          user {
            uid
            firstName
            lastName
          }
        }
        moderators {
          uid
        }
        users {
          uid
          firstName
          lastName
          jobTitle
          banned
          pictureFileResource {
              uid
              path
          }
        }
        inactiveUsers {
          uid
          firstName
          lastName
          jobTitle
          banned
          pictureFileResource {
            uid
            path
          }
        }
        target {
          __typename
          ... on Speaker {
            uid
          }
          ... on Meeting {
            uid
            subject
            startTimestamp
            createdTime
            creatorUser {
              uid
              firstName
              lastName
              jobTitle
              banned
              pictureFileResource {
                uid
                path
              }
            }
            participants {
              uid
              state
              user {
                uid
                firstName
                lastName
                jobTitle
                banned
                pictureFileResource {
                  uid
                  path
                }
              }
            }
          }
          ... on Exhibitor {
            uid
            name
            logoFileResource {
              schemaCode
              path
            }
          }
          ... on Session {
            uid
            name
          }
        }
      }
      ... on EventGroupStateOutput {
        uid
        muted
        lastReadTimestamp
        hideMessageBeforeTimestamp
        user {
          uid
        }
        group {
          uid
        }
      }
      ... on EventMessageOutput {
        uid
        type
        tempId
        schemaCode
        senttime
        senttimestamp
        content
        userDeleted
        target {
          __typename
          ... on Deal {
            uid
            name
            title
            exhibitor {
              uid
              name
            }
            displayFileResource {
              schemaCode
              path
            }
          }
          ... on Product {
            uid
            name
            exhibitor {
              uid
              name
            }
            images {
              fullFileResource {
                schemaCode
                path
              }
            }
          }
          ... on LargeProduct {
            uid
            name
            exhibitor {
              uid
              name
            }
            images {
              fullFileResource {
                schemaCode
                path
              }
            }
          }
        }
        parentMessage {
          uid
          senttime
          senttimestamp
          content
          userDeleted
          user {
            uid
            firstName
            lastName
            jobTitle
            banned
            pictureFileResource {
              uid
              path
            }
          }
        }
        childMessages {
          uid
          userDeleted
        }
        user {
          uid
          firstName
          lastName
          jobTitle
          banned
          pictureFileResource {
            uid
            path
          }
        }
        group {
          uid
          myState {
            muted
            user {
              uid
            }
          }
        }
      }
      ... on UserOutput {
        uid
        firstName
        lastName
        jobTitle
        banned
        pictureFileResource {
          uid
          path
        }
      }
      ... on EventMessageReactionOutput {
        uid
        tempReactionId: tempId
        reaction
        user {
          uid
        }
        message {
          uid
          group {
            uid
          }
        }
      }
    }
  }
`;
